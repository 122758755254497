<template>
  <div>
    <el-form ref="queryForm" :model="queryForm" :inline="true" size="small">
      <el-form-item label="姓名" prop="realname">
        <el-input v-model="queryForm.realname"></el-input>
      </el-form-item>
      <el-form-item label="就诊时间" prop="date_range">
        <el-date-picker v-model="queryForm.date_range" type="daterange" range-separator="至" start-placeholder="开始日期"
          end-placeholder="结束日期" value-format="yyyy-MM-dd" style="width: 260px;">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="记录人" prop="adminer">
        <el-input v-model="queryForm.adminer"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="handleSearch" type="primary">查询</el-button>
        <el-button @click="handleResetQueryForm">重置</el-button>
        <el-button @click="handleAdd" type="primary">新增</el-button>
        <el-button @click="handleImport" type="primary">导入</el-button>
      </el-form-item>
    </el-form>

    <el-table :data="tableData" v-loading="tableLoading" border stripe
      :header-cell-style="{background:'#E4E7ED',color: '#606266'}" size="small" max-height="500px" style="width: 100%;">
      <el-table-column label="#" type="index" width="60"></el-table-column>
      <el-table-column label="姓名" prop="member.realname"></el-table-column>
      <el-table-column label="药物名称" prop="name"></el-table-column>
      <el-table-column label="剂量" prop="num"></el-table-column>
      <el-table-column label="备注" prop="remark"></el-table-column>
      <el-table-column label="操作">
        <template v-slot="{ row }">
          <el-link @click="handleEdit(row)" type="primary" class="margin-r-10">编辑</el-link>
          <el-popconfirm title="确定删除吗？" @confirm="handleDel(row.id)">
            <el-link slot="reference" type="danger">删除</el-link>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <div class="margin-t-10 flex">
      <el-pagination background layout="sizes, prev, pager, next, total, jumper" :total="total"
        :page-sizes="[10, 20, 30]" :current-page.sync="queryForm.page" :page-size.sync="queryForm.page_size"
        @current-change="getMedications" @size-change="getMedications">
      </el-pagination>
      <el-button size="small">确定</el-button>
    </div>

    <!-- 新增、编辑的弹窗 -->
    <el-dialog :title="dialogTitle" :visible.sync="showDialog" :close-on-click-modal="false" :before-close="closeDialog"
      width="500px">
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="姓名" prop="member_id">
          <el-select v-model="form.member_id" clearable filterable remote placeholder="输入姓名进行搜索"
            :remote-method="getUserRecords" :loading="userLoadig" class="el-input_inner--rewrite">
            <el-option v-for="item in userRecords" :key="item.id" :label="item.realname" :value="item.id">
              <div class="flex justify-content-s-b">
                <span>{{ item.realname }}</span>
                <span style="color: #8492a6;">{{ item.mobile }}</span>
              </div>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="药物名称" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="剂量" prop="num">
          <el-input v-model="form.num"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="form.remark" type="textarea"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button :loading="submitting" @click="handleSubmit" type="primary">提 交</el-button>
      </span>
    </el-dialog>

    <!-- 导入的弹窗 -->
    <el-dialog title="导入" :visible.sync="showImportDialog" :close-on-click-modal="false" width="500px">
      <el-link
        href="https://huarenoss.oss-cn-beijing.aliyuncs.com/files/template/%E8%80%81%E5%B9%B2%E9%83%A8%E7%94%A8%E8%8D%AF%E5%8F%B2.xlsx"
        type="primary">导入模板的地址，点击下载</el-link>
      <el-upload ref="uploadFile" action="#" :auto-upload="false" :limit="1" :on-change="handleFileChange"
        class="margin-t-20">
        <el-button size="small" type="primary">选择文件</el-button>
      </el-upload>
      <span slot="footer">
        <el-button @click="showImportDialog = false">取 消</el-button>
        <el-button :loading="submitting" @click="handleConfirmImport" type="primary">导 入</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import {
    getMedicationsAPI,
    saveMedicationAPI,
    delMedicationAPI,
    importMedicationAPI,
  } from '@/api/medication.js'
  import {
    getUserRecordDataAPI
  } from '@/api/user-record/record.js'

  export default {
    name: 'Medication',
    data() {
      return {
        queryForm: {
          realname: '',
          date_range: '',
          adminer: '',
          page: 1,
          page_size: 10
        },

        dialogTitle: '',
        showDialog: false,
        submitting: false,
        form: {
          member_id: '',
          name: '',
          remark: '',
          num: '',
        },
        rules: {
          member_id: [{
            required: true,
            message: '姓名不能为空',
            trigger: 'change'
          }],
          name: [{
            required: true,
            message: '药物不能为空',
            trigger: 'blur'
          }],
          num: [{
            required: true,
            message: '剂量不能为空',
            trigger: 'blur'
          }],
        },

        tableData: [],
        total: 0,
        tableLoading: false,
        curRowId: '',

        // 用户档案
        userRecords: [],
        userLoadig: false,

        // 导入相关
        showImportDialog: false,
        selectedFile: null
      }
    },
    created() {
      this.getMedications()
    },
    methods: {
      handleSearch() {
        this.queryForm.page = 1
        this.getMedications()
      },
      handleResetQueryForm() {
        this.$refs.queryForm.resetFields()
      },
      handleAdd() {
        this.showDialog = true
        this.dialogTitle = '新增'
      },
      // 导入按钮
      handleImport() {
        this.showImportDialog = true
      },
      handleFileChange(file) {
        this.selectedFile = file
      },
      handleConfirmImport() {
        this.submitting = true

        const formData = new FormData()
        formData.append('import_file', this.selectedFile.raw)

        importMedicationAPI(formData).then(() => {
          this.$message.success('导入成功')
          this.getMedications()
        }).catch(() => {
          this.$message.error('请检查文件内容格式')
        }).finally(() => {
          this.$refs.uploadFile.clearFiles()
          this.submitting = false
        })
      },
      closeDialog() {
        this.$refs.form.resetFields()
        this.showDialog = false
      },
      handleEdit(row) {
        this.showDialog = true
        this.dialogTitle = '编辑'
        this.curRowId = row.id
        const copyRow = JSON.parse(JSON.stringify(row))
        // 回显老人
        this.userRecords = [copyRow.member]
        // 回显
        for (let key in this.form) {
          this.form[key] = copyRow[key]
        }
        // 返回的 member_id 是字符串，导致回显失败
        this.form.member_id = parseInt(this.form.member_id)
      },
      handleDel(id) {
        delMedicationAPI({
          id,
        }).then(res => {
          this.$message.success(res.msg)
          this.getMedications()
        })
      },
      handleSubmit() {
        this.$refs.form.validate(isValid => {
          if (!isValid) return

          this.submitting = true
          const copyForm = Object.assign({}, this.form)

          if (this.dialogTitle == '编辑') copyForm.id = this.curRowId

          saveMedicationAPI(copyForm).then(res => {
            this.$message.success(res.msg)
            this.closeDialog()
            this.getMedications()
          }).finally(() => this.submitting = false)
        })
      },

      getMedications() {
        this.tableLoading = true

        getMedicationsAPI(this.queryForm).then(res => {
          this.tableData = res.data
          this.total = res.total
          this.tableLoading = false
        })
      },
      getUserRecords(keyword) {
        this.userLoadig = true

        setTimeout(() => {
          getUserRecordDataAPI({
            keyword,
            page: 1
          }).then(res => {
            this.userRecords = res.data
            this.userLoadig = false
          })
        })
      }
    }
  }
</script>

<style>
</style>